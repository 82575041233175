import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p><em parentName="p">{`Code snippets`}</em>{` are small blocks of reusable code that can be inserted in a code file.`}</p>
    <p>{`Our code snippets are built with basic styling. We recommend using `}<a parentName="p" {...{
        "href": "https://github.com/chriskempson/base16"
      }}>{`Base16`}</a>{` for more complex syntax highlighting. Base16 provides carefully chosen syntax highlighting and a default set of sixteen colors suitable for a wide range of applications. The theme we recommend using is `}<strong parentName="p">{`Solarflare`}</strong>{`.`}</p>
    <h4 {...{
      "id": "overflow"
    }}>{`Overflow`}</h4>
    <p>{`If there are more than nine lines of code, apply vertical scrolling to the code snippet.`}</p>
    <p>{`Terminal commands are often longer strings and should only appear on one line. Apply horizontal scrolling to maintain the set width of the box for those longer strings.`}</p>
    <h2 {...{
      "id": "interaction"
    }}>{`Interaction`}</h2>
    <p>{`Code snippets are accompanied by a `}<strong parentName="p">{`copy icon`}</strong>{`, which allows users to copy the provided code to their clipboard. The copy icon must be accompanied by a confirmation that states the successful action of copying an item to the users clipboard.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "47.55434782608695%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "code snippet example",
        "title": "code snippet example",
        "src": "/static/c86dee9478849fcc5062dd853652e4ae/fb070/code-snippet-usage-1.png",
        "srcSet": ["/static/c86dee9478849fcc5062dd853652e4ae/d6747/code-snippet-usage-1.png 288w", "/static/c86dee9478849fcc5062dd853652e4ae/09548/code-snippet-usage-1.png 576w", "/static/c86dee9478849fcc5062dd853652e4ae/fb070/code-snippet-usage-1.png 1152w", "/static/c86dee9478849fcc5062dd853652e4ae/c3e47/code-snippet-usage-1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      